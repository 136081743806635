import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 项目列表
  teamPageList(data) {
    return request({
      url: '/team/teamPageList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 项目新增
  teamAdd(data) {
    return request({
      url: '/team/teamAdd',
      method: 'post',
      data,
    });
  },
  // 项目编辑
  teamUpdate(data) {
    return request({
      url: '/team/teamUpdate',
      method: 'post',
      data,
    });
  },
  // 项目删除
  teamDelete(data) {
    return request({
      url: '/team/teamDelete',
      method: 'post',
      data,
    });
  },
  // 项目详情
  teamDetail(data) {
    return request({
      url: '/team/teamDetail?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 项目下拉列表
  teamList(data) {
    return request({
      url: '/team/teamList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 项目活动树状列表
  teamClassList(data) {
    return request({
      url: '/team/teamClassList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
};
