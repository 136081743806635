import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 活动列表
  classPageList(data) {
    return request({
      url: '/team_class/classPageList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 活动新增
  classAdd(data) {
    return request({
      url: '/team_class/classAdd',
      method: 'post',
      data,
    });
  },
  // 活动编辑
  classUpdate(data) {
    return request({
      url: '/team_class/classUpdate',
      method: 'post',
      data,
    });
  },
  // 活动删除
  classDelete(data) {
    return request({
      url: '/team_class/classDelete',
      method: 'post',
      data,
    });
  },
  // 活动详情
  classDetail(data) {
    return request({
      url: '/team_class/classDetail?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 活动下拉列表
  classList(data) {
    return request({
      url: '/team_class/classList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
};
