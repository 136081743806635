import { request } from '@/request/request';
import { parameterChange } from '@/utils';

export default {
  // 获取验证码
  uuid(data) {
    return request({
      url: '/auth/uuid',
      method: 'get',
      data,
    });
  },
  // 用户登录
  login(data) {
    return request({
      url: '/auth/login',
      method: 'post',
      data,
    });
  },
  // 用户列表
  userPageList(data) {
    return request({
      url: '/user/userPageList?' + parameterChange(data),
      method: 'get',
      data,
    });
  },
  // 用户新增
  userAdd(data) {
    return request({
      url: '/user/userAdd',
      method: 'post',
      data,
    });
  },
  // 关联项目活动
  userAssociate(data) {
    return request({
      url: '/user/userAssociate',
      method: 'post',
      data,
    });
  },
  // 导入用户信息
  userExcelImport(data) {
    return request({
      url: '/user/userExcelImport',
      method: 'post',
      data,
    });
  },
  // 修改密码
  changePassword(data) {
    return request({
      url: '/auth/changePassword',
      method: 'post',
      data,
    });
  },
};
